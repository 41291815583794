import { articles } from "../constants/articles";
import { Link } from "react-router-dom";
import PatrickPhoto from '../images/face.JPG';
import './blog.css'


const Blog = () => {
    return (
        <main className="blog">
        </main>
    );
}

export default Blog;